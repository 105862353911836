@import 'scss/variables';

@import '~bootstrap/scss/bootstrap-grid.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Arboria', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  background: $cinza-claro 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow-x: hidden;
  @include media-breakpoint-up(fhd) {
    font-size: 19px;
  }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MaterialIcons/MaterialIcons-Regular.eot);
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(./assets/fonts/MaterialIcons/MaterialIcons-Regular.woff2) format('woff2'),
    url(./assets/fonts/MaterialIcons/MaterialIcons-Regular.woff) format('woff'),
    url(./assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

@font-face {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 300;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-Light.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: italic;
    font-weight: 300;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-LightItalic.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 400;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 500;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-Medium.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: italic;
    font-weight: 500;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-MediumItalic.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-Bold.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: italic;
    font-weight: 700;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-BoldItalic.otf) format('opentype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 900;
    src: local('Larsseit'), url(./assets/fonts/Larsseit/Larsseit-ExtraBold.otf) format('opentype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype'),
  }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype'),
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype'),
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 900, bolder;
  src: url(./assets/fonts/Arboria/Arboria-Black.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Black'),
    url(./assets/fonts/Arboria/Arboria-Black.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Black.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 900, bolder;
  src: url(./assets/fonts/Arboria/Arboria-BlackItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-BlackItalic'),
    url(./assets/fonts/Arboria/Arboria-BlackItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-BlackItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 700, bold;
  src: url(./assets/fonts/Arboria/Arboria-Bold.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Bold'),
    url(./assets/fonts/Arboria/Arboria-Bold.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Bold.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 700, bold;
  src: url(./assets/fonts/Arboria/Arboria-BoldItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-BoldItalic'),
    url(./assets/fonts/Arboria/Arboria-BoldItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-BoldItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 400, normal;
  src: url(./assets/fonts/Arboria/Arboria-Book.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Book'),
    url(./assets/fonts/Arboria/Arboria-Book.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Book.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 400, normal;
  src: url(./assets/fonts/Arboria/Arboria-BookItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-BookItalic'),
    url(./assets/fonts/Arboria/Arboria-BookItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-BookItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-BookItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/Arboria/Arboria-Light.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Light'),
    url(./assets/fonts/Arboria/Arboria-Light.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Light.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 300;
  src: url(./assets/fonts/Arboria/Arboria-LightItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-LightItalic'),
    url(./assets/fonts/Arboria/Arboria-LightItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-LightItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 100, lighter;
  src: url(./assets/fonts/Arboria/Arboria-Thin.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Thin'),
    url(./assets/fonts/Arboria/Arboria-Thin.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Thin.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 100, lighter;
  src: url(./assets/fonts/Arboria/Arboria-ThinItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-ThinItalic'),
    url(./assets/fonts/Arboria/Arboria-ThinItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-ThinItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 500, normal;
  src: url(./assets/fonts/Arboria/Arboria-Medium.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Medium'),
    url(./assets/fonts/Arboria/Arboria-Medium.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-Medium.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: italic;
  font-weight: 500, normal;
  src: url(./assets/fonts/Arboria/Arboria-MediumItalic.eot) format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-MediumItalic'),
    url(./assets/fonts/Arboria/Arboria-MediumItalic.woff2) format('woff2'),
    url(./assets/fonts/Arboria/Arboria-MediumItalic.woff) format('woff'),
    url(./assets/fonts/Arboria/Arboria-MediumItalic.ttf) format('truetype');
}
